import React from 'react';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { getLogData, getLevel } from '../../helpers/common';
import { useCriteriaContext } from '../../context/criteria.context';
import { createActivityLog } from '../../utils/apiCall';
import { GetFQURL } from '../model/store.swr';
import { getAccessToken } from '../auth/userinfo';
import axios from 'axios';
import { errorCodes } from '../modelviewer/heatmap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { ViewRecommendation } from "./ViewRecommendation";


export const timeRangeCheck = (start_time, end_time) => {
    const ts = moment(start_time.split("T")[0], "YYYYMMDD").year();
    const te = moment(end_time.split("T")[0], "YYYYMMDD").year();
    if (ts >= 2023 && te >= 2023) {
        return true;
    }
    return false;
};



const convertStringToTimestamp = (string, timezone) => {
    return moment.unix(string).tz('UTC').tz(timezone).format('YYYY-MM-DDTHH:mm:ss');
}

const convertTimeStampToHours = (timestamp) => {
    const duration = moment.duration(timestamp, 'seconds');
    const years = duration.years() * 365 * 24;
    const months = duration.months() * 30 * 24;
    const days = duration.days() * 24;
    const hours = duration.hours();
    const minutes = duration.minutes() / 60;
    const calc_duration = Math.floor(years + months + days + hours + minutes);
    return calc_duration;
}

export const makeSummaryData = (data, level) => {
    const issueData = [];
    if (level === 'pf') {
        data.map((value, index) => {
            issueData.push({
                id: index,
                issue: value.issue === "PF No Data" ? "No Portfolio Data" : value.issue === "Site No Data" ? "Sites With No Data" : errorCodes[value.issue],
                avg_secs: convertTimeStampToHours(value.avg_duration),
                max_secs: convertTimeStampToHours(value.max_duration),
                occurrences: value.occurrences,
                devices_with_issue: value.sites_with_issue ? value.sites_with_issue : " ",
                ava_impact: value.ava_impact,
                rec: <ViewRecommendation rec={value.rec ? value.rec : ["TBD"]}/>,
                // rec: "TBD",
            });

        })
    }
    if (level === "site") {
        data.map((value, index) => {
            issueData.push({
                id: index,
                issue:
                    value.issue === "Site No Data"
                        ? "No Site Data"
                        : value.issue === "Zone No Data"
                        ? "Zones With No Data"
                        : value.issue === "Device No Data" ? "Tracker With No Data" : errorCodes[value.issue],
                avg_secs: convertTimeStampToHours(value.avg_duration),
                max_secs: convertTimeStampToHours(value.max_duration),
                occurrences: value.occurrences,
                devices_with_issue: value.zones_with_issue
                    ? value.zones_with_issue
                    : " ",
                total_zones: value.available_zones
                    ? value.available_zones
                    : " ",
                ava_impact: value.ava_impact,
                rec: <ViewRecommendation rec={value.rec ? value.rec : ["TBD"]}/>,
                // rec: "TBD"
            });
        })
    }
    if (level === "zone") {
        data.map((value, index) => {
            issueData.push({
                id: index,
                issue:
                    value.issue === "Zone No Data"
                        ? "No Zone Data"
                        : value.issue === "Device No Data"
                        ? "Trackers With No Data"
                        : errorCodes[value.issue],
                avg_secs: convertTimeStampToHours(value.avg_duration),
                max_secs: convertTimeStampToHours(value.max_duration),
                occurrences: value.occurrences,
                devices_with_issue: value.devices_with_issue ? value.devices_with_issue : " ",
                total_devices: value.available_devices ? value.available_devices : " ",
                ava_impact: value.ava_impact,
                rec: <ViewRecommendation rec={value.rec ? value.rec : ["TBD"]}/>,
                // rec: "TBD"
            });
        })
    }
    if (level === "rc"){
            data.map((value, index) => {
            issueData.push({
                id: index,
                issue: value.issue !== "No Data" ? errorCodes[value.issue] : "No Tracker Data",
                avg_secs: convertTimeStampToHours(value.avg_duration),
                max_secs: convertTimeStampToHours(value.max_duration),
                occurrences: value.occurrences,
                ava_impact: value.ava_impact,
                rec: <ViewRecommendation rec={value.rec ? value.rec : ["TBD"]}/>,
                // rec: "TBD"
            });
        });
    }
    console.log("issueData: ", issueData)
    return issueData;
}

export const makeDetailedData = (data, timezone, level) => {
        console.log("ddata: ", data, timezone)
        let dData = {};
        if (level === "pf") {
            for (const [key, value] of Object.entries(data)) {
                dData[key] = value.map((v, index) => ({
                    id: index,
                    site_id: v.site_id ? v.site_id : null,
                    device_id: v.device_id ? v.device_id : null,
                    zone_id: v.zone_id ? v.zone_id : null,
                    row_id: v.row_id ? v.row_id : null,
                    avg_secs: convertTimeStampToHours(v.avg_duration),
                    max_secs: convertTimeStampToHours(v.max_duration),
                    occurrences: v.occurrences,
                    devices_with_issue:
                        key === "partial_nodata"
                            ? v.total_sites
                            : key === "pf_no_data"
                            ? null
                            : null,
                    available_devices: v.total_sites,
                    start_time: convertStringToTimestamp(
                        v.start_time,
                        timezone
                    ),
                    end_time: convertStringToTimestamp(v.end_time, timezone),
                    duration: convertTimeStampToHours(v.duration),
                }));
            }
        }
        if (level === "site") {
            for (const [key, value] of Object.entries(data)) {
                dData[key] = value.map((v, index) => ({
                    id: index,
                    zone_id: v.zone_id ? v.zone_id : null,
                    row_id: v.row_id ? v.row_id : null,
                    device_id: v.device_id ? v.device_id : null,
                    avg_secs: convertTimeStampToHours(v.avg_duration),
                    max_secs: convertTimeStampToHours(v.max_duration),
                    occurrences: v.occurrences,
                    devices_with_issue:
                        key === "partial_nodata"
                            ? v.total_zones_with_noData
                            : key === "site_no_data"
                            ? null
                            : v.devices_with_alerts,
                    available_devices: v.total_zones,
                    start_time: convertStringToTimestamp(
                        v.start_time,
                        timezone
                    ),
                    end_time: convertStringToTimestamp(v.end_time, timezone),
                    duration: convertTimeStampToHours(v.duration),
                }));
            }
        }
        if (level === "zone") {
            for (const [key, value] of Object.entries(data)) {
                dData[key] = value.map((v, index) => ({
                    id: index,
                    row_id: v.row_id ? v.row_id : null,
                    device_id: v.device_id ? v.device_id : null,
                    avg_secs: convertTimeStampToHours(v.avg_duration),
                    max_secs: convertTimeStampToHours(v.max_duration),
                    occurrences: v.occurrences,
                    devices_with_issue:
                        key === "partial_nodata"
                            ? v.devices_with_nodata
                            : key === "zone_no_data"
                            ? null
                            : v.devices_with_alerts,
                    available_devices: v.total_devices,
                    start_time: convertStringToTimestamp(
                        v.start_time,
                        timezone
                    ),
                    end_time: convertStringToTimestamp(v.end_time, timezone),
                    duration: convertTimeStampToHours(v.duration),
                }));
            }
        }
        if (level === "rc") {
            for (const [key, value] of Object.entries(data)) {
                dData[key] = value.map((v, index) => ({
                    id: index,
                    start_time: convertStringToTimestamp(
                        v.start_time,
                        timezone
                    ),
                    end_time: convertStringToTimestamp(
                        v.end_time,
                        timezone
                    ),
                    duration: convertTimeStampToHours(v.duration),
                }));
            }
        }
        console.log("ddData: ", dData)
        return dData;
    };

export const paginationOptions = {
    sizePerPage: 10,
    hidePageListOnlyOnePage: true,
    hidePerPage: 10,
    hideSizePerPage: true,
};

export const applySort = (sortCriteria, sortData) => {
    const _sorted = sortCriteria.filter(
        (t) => t.sorted === "asc" || t.sorted === "desc"
    );
    if (_sorted.length > 0) {
        const sorted = _sorted[0].sorted;
        const dataField = _sorted[0].dataField;

        const sortedData = sortData.sort((a, b) => {
            if (sorted === "asc") {
                return a[dataField] > b[dataField] ? 1 : -1;
            } else {
                return a[dataField] > b[dataField] ? -1 : 1;
            }
        });
        return sortedData;
    } else {
        return sortData;
    }
};

const getColumnSorted = (value, sortedColumns) => {
    return sortedColumns
        .map((val) => (val.dataField === value ? val : null))
        .find((val) => val !== null);
};

export const showArrow = (sortedRow, value) => {
    const columnSorted = getColumnSorted(value, sortedRow);
    return columnSorted.sorted ? (
        columnSorted.sorted === "desc" ? (
            <img
                src="../assets/img/icon/icon-carbon_caret-sort-down.svg"
                width="17"
                height="16"
            />
        ) : (
            <img
                src="../assets/img/icon/icon-carbon_caret-sort-up.svg"
                width="17"
                height="16"
            />
        )
    ) : (
        ""
    );
};

export const sortColumn = (value, data, setData, row, setRow) => {
    const newRowSort = row.map((t) => ({
        ...t,
        sorted:
            t.dataField === value
                ? t.sorted === "asc"
                    ? "desc"
                    : "asc"
                : null,
    }));
    const newData = applySort(newRowSort, data);
    setData(newData);
    setRow(newRowSort);
};

function DownloadData({filename, headers, data}) {
    console.log("issueSummary:", data, filename, headers)
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const csvReport = {
        filename: filename + ".csv",
        headers: headers,
        data: data.map((val) => ({ ...val, rec: val.rec ? JSON.parse((val.rec.props.rec)) : val })),
        // data: data
    };
    const criteria = useCriteriaContext();
    const level = getLevel(criteria);
    const logsData = data.map((val) => ({ ...val, rec: "" }))
    // const logsData = data
    const logData = getLogData(criteria, {
        featureId: `download-${level}-summary`,
        usecase: `download ${level} summary`,
        category: "overview",
        level: level,
        details: {...logsData, fileType: "csv"}
    });

    const handlePdfDownload = (filename, headers, data) => {
            console.log("pdfData:", data)
            data.map((value) => (delete value["id"]))
            const head = []
            head.push(Object.values(headers).map((value) => value.label))
            const body = [];
            data.map((value) => {
                body.push(Object.values(value))
            })
            console.log("pdfBody", head, body)
            const doc = new jsPDF("l");
            autoTable(doc, {
                head: head,
                body: body,
                horizontalPageBreak: true,
                horizontalPageBreakRepeat: 0,
            });
            doc.save(filename + ".pdf");
    }

    return (
        <>
            <div className="drop-down-menu">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle>
                        <img
                            src="../assets/img/icon/icon-download.svg"
                            alt="Download"
                            width="16"
                            height="16"
                            className="chart-download"
                        />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem
                            onClick={() => createActivityLog(logData)}
                        >
                            <CSVLink {...csvReport}>
                                <img
                                    src="../assets/img/icon/icon-csv.svg"
                                    alt="Download"
                                    width="24"
                                    height="24"
                                    className='chart-download-menu-icon'
                                />
                                Download as CSV
                            </CSVLink>
                        </DropdownItem>
                        {/* <DropdownItem
                            onClick={() => handlePdfDownload(filename, headers, data)}
                        >
                            <img
                                src="../assets/img/icon/icon-pdf.svg"
                                alt="Download"
                                width="24"
                                height="24"
                                className="chart-download-menu-icon"
                            />
                            Download as PDF
                        </DropdownItem> */}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
    );
}

export const DownloadCsv_Issue_Detail_table = ({ data, filename, level, selectedRow }) => {
    if (level === "pf") {
        try {
            if (selectedRow === "No PF Data"){
                var colname = [
                    { label: "Start Time", key: "start_time" },
                    { label: "End Time", key: "end_time" },
                    {
                        label: "Duration (Hours)",
                        key: "duration",
                    },
                ];
            }
            else if (selectedRow === "Sites With No Data") {
                var colname = [
                    { label: "Site ID", key: "site_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max. Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            }
            else {
                var colname = [
                    { label: "Site ID", key: "site_id" },
                    { label: "Zone ID", key: "zone_id" },
                    { label: "Tracker ID", key: "device_id" },
                    { label: "Row ID", key: "row_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max. Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            }
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    if (level === "site") {
        try {
            if (selectedRow === "No Site Data") {
                var colname = [
                    { label: "Start Time", key: "start_time" },
                    { label: "End Time", key: "end_time" },
                    { label: "Duration (Hours)", key: "duration" },
                ];
            } else if (selectedRow === "Zones With No Data") {
                var colname = [
                    { label: "Zone ID", key: "zone_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            } else if (selectedRow === "Tracker With No Data") {
                var colname = [
                    { label: "Zone ID", key: "zone_id" },
                    { label: "Tracker ID", key: "device_id" },
                    { label: "Row ID", key: "row_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            } else {
                var colname = [
                    { label: "Zone ID", key: "zone_id" },
                    { label: "Tracker ID", key: "device_id" },
                    { label: "Row ID", key: "row_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            }
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    if (level === "zone") {
        try {
            if (selectedRow === "No Zone Data") {
                var colname = [
                    { label: "Start Time", key: "start_time" },
                    { label: "End Time", key: "end_time" },
                    { label: "Duration (Hours)", key: "duration" },
                ];
            } else if (selectedRow === "Trackers With No Data") {
                var colname = [
                    { label: "Row ID", key: "row_id" },
                    { label: "Tracker ID", key: "device_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            } else {
                var colname = [
                    { label: "Row ID", key: "row_id" },
                    { label: "Tracker ID", key: "device_id" },
                    { label: "Avg. Duration (Hours)", key: "avg_secs" },
                    { label: "Max Duration (Hours)", key: "max_secs" },
                    { label: "No. of Occurrences", key: "occurrences" },
                ];
            }
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    if (level === "rc" || selectedRow === "No Data") {
        try {
            var colname = [
                { label: "Start Time", key: "start_time" },
                { label: "End Time", key: "end_time" },
                { label: "Duration (HH)", key: "duration" },
            ];
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    return <></>;
};

export const DownloadCsv_Issue_Summary_table = ({ data, filename, level }) => {
    console.log("DownloadData: ", data)
    if (level === "pf") {
        try {
            var colname = [
                { label: "Issue", key: "issue" },
                { label: "Avg. Duration (Hours)", key: "avg_secs" },
                { label: "Max Duration (Hours)", key: "max_secs" },
                { label: "No. of Occurrences", key: "occurrences" },
                { label: "Availability Impact (%)", key: "ava_impact" },
                { label: "Recommendation", key: "rec" },
            ]
            return (<DownloadData filename={filename} headers={colname} data={data}/>);
        } catch {
            return <></>;
        }
    }
    if (level === "site") {
        try {
            var colname = [
                { label: "Issue", key: "issue" },
                { label: "Avg. Duration (Hours)", key: "avg_secs" },
                { label: "Max Duration (Hours)", key: "max_secs" },
                { label: "No. of Occurrences", key: "occurrences" },
                { label: "Availability Impact (%)", key: "ava_impact" },
                { label: "Recommendation", key: "rec" },
            ];
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    if (level === "zone") {
        try {
            var colname = [
                { label: "Issue", key: "issue" },
                { label: "Avg. Duration (Hours)", key: "avg_secs" },
                { label: "Max Duration (Hours)", key: "max_secs" },
                { label: "No. of Occurrences", key: "occurrences" },
                { label: "Availability Impact (%)", key: "ava_impact" },
                { label: "Recommendation", key: "rec" },
            ];
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    if (level === "rc"){
        try {
            var colname = [
                { label: "Issue", key: "issue" },
                { label: "Avg. Duration (Hours)", key: "avg_secs" },
                { label: "Max Duration (Hours)", key: "max_secs" },
                { label: "Occurrence", key: "occurrences" },
                { label: "Availability Impact (%)", key: "ava_impact" },
                { label: "Recommendation", key: "rec" },
            ];
            return (
                <DownloadData
                    filename={filename}
                    headers={colname}
                    data={data}
                />
            );
        } catch {
            return <></>;
        }
    }
    return <></>
};

const parseData = (data, setSummaryData, sortCriteria, setDetailData, setTimeZoneData, level) => {
    let dData = {}
    const parsedData = Array.isArray(data) ? data[0] : data.data;
    console.log("parsedData: ", parsedData)
    const timezone =
        parsedData["timezone"].length === 0
            ? "Etc/UTC"
            : parsedData["timezone"][0]["timezone"]
            ? parsedData["timezone"][0]["timezone"]
            : "Etc/UTC";
    console.log("timezone", timezone)
    const keys = Object.keys(parsedData);
    const sData = keys.map((value) =>
        parsedData[value]["summary_data"]
            ? JSON.parse(parsedData[value]["summary_data"])
            : null
    ).filter(Boolean).flat();
    keys.forEach((value) => {
        const dict = parsedData[value]["detail_data"];
        console.log("dict: ", value, dict)
        if (dict) {
            if (value !== "alerts") {
                dData[value] = dict;
            } else {
                dData = { ...dict, ...dData };
            }
        }
    })
    setDetailData(makeDetailedData(dData, timezone, level));
    setSummaryData(applySort(sortCriteria, makeSummaryData(sData, level)));
    setTimeZoneData(timezone);
};



export const getData = (urls, criteria, log, setSummaryData, sortCriteria, setDetailData, setTimeZoneData, level, setIsReady) => {
    let token = getAccessToken();
    const url = GetFQURL(
        urls[0],
        criteria,
        "json",
        false,
        [],
        null,
        null,
        null,
        log
    );
    console.log("URL: ", url)
    console.log("Level: ", level)
    axios
        .get(url, {
            headers: {
                "x-access-token": token,
            },
        })
        .then((response) => {
            console.log("ResponseData: ", response.data, typeof(response.data))
            setIsReady(true)
            parseData(
                response.data,
                setSummaryData,
                sortCriteria,
                setDetailData,
                setTimeZoneData,
                level
            );
        })
        .catch((error) => {
            console.error("Error Fetching data: ", error);
        });
};
