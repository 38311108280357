import { toJS } from 'mobx';
import React, {useEffect, useState} from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { CardStats as Card } from '../layout/Card';
import { ModeDomainToChart } from '../model/mode.data';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import { ZonesOfflineTable } from '../table/offlinezones';
import StatsOverview, { StatsOverviewPopup } from './statsChart';
import { getLevel, getLogData, getWeightedAvg } from '../../helpers/common';
import { GetFQURL } from '../model/store.swr'; 
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range' 
import { getStatsData } from '../../utils/apiCall';
import _ from 'lodash'
import { useUserContext } from '../../context/user.context';
import { getSites } from '../../model/metadata';
import axios from 'axios';
import { domainsByCriteria } from '../layout/criteria';
import { useCriteriaContext } from '../../context/criteria.context';
import { Card4Opacity80 , CardSummary } from '../layout/Card';
import { InfoTooltip } from '../../model/info-tooltip';
import { usePageActionContext } from '../../context/page.context'
import ReactDOMServer from 'react-dom/server';
import TableContainer from '../table/TableContainer';
import { getWeatherIcon } from './weatherForecast';
import { isPortfolio, isSite } from '../layout/criteria';
import { useAvailabilityContext } from '../../context/availability.context';
import { WeatherTableContainer } from './weatherForecast';

export const getProgressValue = (value, value2) => {
    return (value / value2) * 100;
}

export const UptimeOverview = (props) => {
    const c = toJS(useStore().criteria)
    const userContext = useUserContext()
    const [criteria,setCriteria] = useState(c)
    const [tenants, setTenants] = useState([])
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-positional-accuracy-overview`,  usecase: `view ${level} positional accuracy overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const availabilityContext = useAvailabilityContext()
    const activeZones = availabilityContext.activeZones

    var uptime_urls = [{ name: "uptime", url: "rc_uptime_7days", grouptype: "sites", slice: 999 }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.device !== c.device || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])

    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                uptime_urls = [{ name: "uptime", url: "rc_uptime_7days_combined", grouptype: "sites", slice: 999 }]
            }
            
            if(tenants.length){
                console.log("In Uptime card",uptime_urls)
                let url = GetFQURL(uptime_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In Uptime card 1",uptime_urls)
            let url = GetFQURL(uptime_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.device, criteria.Ts, criteria.Te])
    // const response = FetchData2(useSWR, criteria, props.urls, 'json',logData)

    if (_.isEmpty(response)) return <Card>Loading Uptime Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Uptime Stats ...</Card>
    
    var uptimeArr = [...response]

    
    
    const uptime = uptimeArr[0].uptime;
    // const uptime = response[0].uptime;
    console.log("uptime....",uptime)

    const data = { suffix: uptime ? " %" : "", value: uptime ? uptime : 'No Data'  , timeperiod: 'Last 7 days', color: "#4daf4a", progress: uptime };

    return (<StatsOverview data={data} title="Positional Accuracy " tooltip={TooltipHelpContent.UptimeOverview} bar="true" type="uptime" toolTipPointer={'right'}/>)
}

export const AvailabilityOverview = (props) => {
    const c = toJS(useStore().criteria)
    const userContext = useUserContext()
    const [tenants, setTenants] = useState([])
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-tracker-availability-overview`,  usecase: `view ${level} tracker availability overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    const pageActionContext = usePageActionContext()


    const availabilityContext = useAvailabilityContext()
    const activeZones = availabilityContext.activeZones 
    var availability_urls = [{ name: "availability", url: "rc_availability_7days", grouptype: "sites", slice: 999 }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.device !== c.device || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])



    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                availability_urls = [{ name: "availability", url: "rc_availability_7days_combined", grouptype: "sites", slice: 999 }]
            }
            
            if(tenants.length){
                console.log("In availability card",availability_urls)
                let url = GetFQURL(availability_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In availability card 1")
            const url = GetFQURL(availability_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.device, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Availability Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Availability Stats ...</Card>

    var availabilityArr = [...response]
    
    
    console.log("availabilityArr",criteria)
    const availability = availabilityArr[0].availability;
    const data = { suffix: availability ? " %" : "", value: availability ?  availability : 'No Data' , timeperiod: 'Last 7 days', color: "#4daf4a", progress: availability, info: availabilityContext.withMaintenance7day ? "Adjusted for maintenance activities" : "", infoclick:() => pageActionContext.setPage("Site Maintenance", [], false) };

    return (<StatsOverview data={data} title="Tracker Availability " tooltip={TooltipHelpContent.AvailabilityOverview} bar="true" type="availability" toolTipPointer={'right'} />)
}

export const MaintenanceOverview = props => {

    var c = toJS(useStore().criteria)
    const [criteria, setCriteria] = useState(c)
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-maintenance-overview`,  usecase: `view ${level} maintenance overview`, category: 'overview', level: level})
    
    const pageActionContext = usePageActionContext()


    const availabilityContext = useAvailabilityContext()
    const {  maintenanceActivities, maintenanceError } = availabilityContext

    // if (_.isEmpty(response)) return <Card>Loading Maintenance Stats ...</Card>
    if (maintenanceError) return <Card>Error In Loading Maintenance Stats ...</Card>

    const data = { 
        suffix: " activities ", 
        value: maintenanceActivities,
        // timeperiod: "Past 60 minutes", 
        color: "red", 
        // value2: totaldevices, 
        // progress: getProgressValue(errorDeviceCount, totaldevices) 
        // action: {
        //     text: "Adjust Availability",
        //     description: "Adjust availability to accomodate maintenance activities if trackers were operational during the activity",
        //     revert: () => setWithMaintenance( false ),
        //     apply: () => setWithMaintenance(true),
        // }
    };

    return (maintenanceActivities === 0 ? null : <StatsOverview data={data} title="Maintenance Activities" tooltip={TooltipHelpContent.MaintenanceOverview} bar="true" type="ratio" toolTipPointer={'left'}  onClick={() => pageActionContext.setPage("Site Maintenance", [], false)} />)
}

export const ErrorOverview = (props) => {

    var c = toJS(useStore().criteria)
    const [criteria, setCriteria] = useState(c)
    const userContext = useUserContext()
    const [tenants, setTenants] = useState([])
    // criteria = {pf: criteria.pf, site: criteria.site, zone: criteria.zone, Ts: criteria.Ts, Te: criteria.Te}
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-tracker-with-errors-overview`,  usecase: `view ${level} tracker with errors overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    var error_urls = [{ name: "errordevices", url: "rc_error_stats", slice: 999, grouptype: "rcs" }]

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])

    useEffect(() => { // This effect work only for pf level
        
        if(criteria.site == ''){
            if(Array.isArray(tenants) && tenants.length > 1){
                error_urls = [{ name: "errordevices", url: "rc_error_stats_combined", slice: 999, grouptype: "rcs" }]
            }
            
            if(tenants.length){
                console.log("In errors card",error_urls)
                let url = GetFQURL(error_urls[0], criteria, 'json', false,[],null,null,null,logData)
                getStatsData(url,setResponse,setError)
            }
        }
        
        
    },[tenants,criteria.Ts, criteria.Te])

    useEffect(() => {
        if(criteria.site != ''){
            console.log("In errors card 1")
            const url = GetFQURL(error_urls[0], criteria, 'json', false,[],null,null,null,logData)
            getStatsData(url,setResponse,setError)
        }
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Error Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Error Stats ...</Card>


    var errorCount  = 0
    var errorDeviceCount = 0
    var totaldevices = 0 
    
    if (response && response.length>0) {
        errorDeviceCount = response.find(f => f.name === "error_devices")["value"]
        totaldevices = response.find(f => f.name === "total_devices")["value"]
    }



    const data = { 
        suffix: " RCs ", 
        value:  errorDeviceCount ,
        timeperiod: "Past 60 minutes", 
        color: "red", 
        value2: totaldevices, 
        progress: getProgressValue(errorDeviceCount, totaldevices) 
    };

    return (<StatsOverview data={data} title="Trackers with Errors" tooltip={TooltipHelpContent.RCsWithErrors} bar="true" type="ratio" toolTipPointer={'left'} />)
}

export const ZonesOverview = (props) => {


    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-zones-communicating-overview`,  usecase: `view ${level} zones communicating overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null,logData)
        getStatsData(url,setResponse,setError)
    },[criteria.pf, criteria.Ts, criteria.Te])

    console.log("response zone",response)

    if (_.isEmpty(response)) return <Card>Loading Zones Stats ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Zones Stats ...</Card>

    
    const responseData = Array.isArray(response) ? response[0] : response.data
    const zonesCommObj = responseData.zonesComm[0];
    const zonesCount = responseData.zones.length;
    const zonesOffline = responseData.zonesoffline;

    const data = { suffix: " zones ", value: zonesCommObj.totalZones , timeperiod: "Past 30 minutes", color: "#4daf4a", value2: zonesCount, progress: getProgressValue(zonesCommObj.totalZones, zonesCount) };

    return (<StatsOverviewPopup data={data} title="Zones Communicating"  toolTipPointer={'right'} tooltip={TooltipHelpContent.ZonesOverview} popChild={ZonesOfflineTable} popData={{data: zonesOffline}}/>)
}

export const AngleOverview = (props) => {


    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    const logData = getLogData(criteria, {featureId: `view-${level}-average-angle-overview`,  usecase: `view ${level} average angle overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null,logData)
        getStatsData(url,setResponse,setError)
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])

    if (_.isEmpty(response)) return <Card>Loading Current Angle ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Error Stats ...</Card>

    // if (!response.isReady) return <Card>Loading Current Angle ... </Card>
    
    if(response && response.length){
        const rc_avg_angle = response[0].value === null ? 'NA' : response[0].value.toFixed(2)+ ' º'
        const data = { "value": rc_avg_angle , "timeperiod": "Past 60 minutes", "color": "#4daf4a" };
        return (<StatsOverview data={data} title="Average Angle​" tooltip={TooltipHelpContent.AverageAngle} />)
    }
}

export const ModeOverview = (props) => {


    const c = toJS(useStore().criteria)
    const [criteria,setCriteria] = useState(c)
    const level = getLevel(useStore().criteria)
    let logData = getLogData(criteria, {featureId: `view-${level}-mode-overview`,  usecase: `view ${level} mode overview`, category: 'overview', level: level})
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})

    function filter(data){
        return true
    }

    useEffect(() => {
        if(criteria.pf !== c.pf || criteria.site !== c.site || criteria.zone !== c.zone || criteria.Ts !== c.Ts || criteria.Te !== c.Te){
            setCriteria(c)
        }
    },[c])

    useEffect(() => {
        const url = GetFQURL(props.urls[0], criteria, 'json', false,[],null,null,null, logData)
        getStatsData(url,setResponse,setError)
    },[criteria.site, criteria.zone, criteria.Ts, criteria.Te])


    if (_.isEmpty(response)) return <Card>Loading Mode overview ...</Card>
    if (!_.isEmpty(error)) return <Card>Error In Loading Mode overview ...</Card>


    
    const responseData = Array.isArray(response) ? response[0] : response.data
    
    if(responseData.allmodes){
        const dps = ModeDomainToChart(responseData.modemajoritytotal, responseData.allmodes, filter)
        if (dps.datasets.length != 0){
            const modetype = dps.datasets.reduce((a,b)=>parseFloat(a.data[0])>parseFloat(b.data[0])? a:b)
            const ModeOverview = {"value": modetype.label, "timeperiod":"Past 60 minutes", progress: modetype.data[0]};
            return (<StatsOverview  data={ModeOverview} title="Mode" tooltip={TooltipHelpContent.CurrentMode} />)
        } 
    }
    return <div></div>
    
}

export const EnergyOverview = (props) => {
    const criteria = toJS(useStore().criteria)
    const response = FetchData2(useSWR, criteria, props.urls)

    if (!response.isReady) return <Card>Loading Energy Stats ...</Card>

    const energyobj = response.data.energy;
    var totalExpected = 0;
    var totalActual = 0;
    var progressValue = 0;
    var totalDataCount = ""
    if(energyobj && energyobj.length > 0 ){
        energyobj.forEach(element => {
            totalActual = totalActual + element.actual_power;
            totalExpected = totalExpected + element.expected_power;
        });
        totalActual = totalActual.toFixed(2);
        totalExpected = totalExpected.toFixed(2);
        progressValue = (totalActual/totalExpected) * 100;
    }
    //(energyobj && energyobj.length > 0 && uptimeobj[0].uptime) ? uptimeobj[0].uptime : 0

    const data = { suffix: " MWh", value: totalActual , value2:totalExpected, timeperiod: '', color: "#4daf4a", progress: progressValue,totalDataCount:(energyobj.length + (criteria.site === ""?" Sites":" Inverters")) };

    return (
        <StatsOverview data={data} title="Energy Produced " tooltip={TooltipHelpContent.EnergyProduced} bar="true" type="energy"/>
    )
}

export const WeathersOverview = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [forecastData, setForecastData] = useState(null);
    const [error, setError] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const criteriaContext = useCriteriaContext();
    const criteria = useCriteriaContext();
    const domainArray = domainsByCriteria(criteria);
    const siteValueIndex = domainArray.indexOf('site');
    const siteValue = siteValueIndex !== -1 ? criteria.site : null;
    const [showTable, setShowTable] = useState(false);
      

    useEffect(() => {
        const initializeValuesFromSites = () => {
            setIsReady(false); // Set loading state to false before fetching data
            getSites(criteriaContext, (data) => {
                if (data && data.length > 0) {
                    const selectedSite = data.find(site => site.site_id === siteValue);
                    console.log("selectedSite", selectedSite);
                    if (selectedSite) {
                        const { latitude, longitude } = selectedSite;
                        setLatitude(latitude);
                        setLongitude(longitude);
                        setIsReady(true);
                        // Fetch weather data once latitude and longitude are set
                        FetchWeather(latitude, longitude);
                    } else {
                        console.error(`Site with value ${siteValue} not found`);
                    }
                } 
            });
        };
        initializeValuesFromSites();
    
        async function FetchWeather(latitude, longitude, retryCount = 0) {
            if (retryCount >= 3) {
                setError("Weather forecast data is not available");
                return;
            }
            if (latitude !== null && longitude !== null) {
                try {
                    const nwsResponse = await axios.get(`https://api.weather.gov/points/${latitude},${longitude}`);
                    const forecastUrl = nwsResponse.data.properties.forecast;
                    const forecastResponse = await axios.get(forecastUrl);
                    setForecastData(forecastResponse.data.properties.periods);
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 500) {
                            // If 500 error occurs, retry fetching after 5 seconds
                            console.error("Error fetching weather data. Retrying in 5 seconds...", error.response.status);
                            await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds
                            FetchWeather(latitude, longitude, retryCount +1); // Retry fetching
                        } else if (error.response.status === 404) {
                            setError("Weather forecast is not available for this location");
                        } else {
                            setError("Weather forecast data is not available");
                        }
                    } else {
                        setError("Weather forecast data is not available");
                    }
                }
            } else {
                setError("Latitude and longitude are not updated for this site.");
            }
        }   
        setError("");
    }, [siteValue]);
    
    if (!isReady) return <Card>Loading Weather Forecast ...</Card>;
    if (error) return <Card>{error}</Card>;
    if (!forecastData) return <Card>Weather forecast data is not  available</Card>;

    const tooltipContent = TooltipHelpContent.WeatherOverview
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    const handleClick = (e) => {
        e.preventDefault();
        setShowTable((prevShowTable) => !prevShowTable);
    }
    
    const handleTableHeaderClick = (e) => {
        e.stopPropagation(); 
      };

    const columns = [
        { Header: "Day",accessor: "name"}, { Header: "Temperature (°F)",accessor: "temperature"}, { Header: "Short Forecast",accessor: "shortForecast"}, { Header: "Wind Speed",accessor: "windSpeed"}, { Header: "Precipitation (%)",accessor: "probabilityOfPrecipitation"}
    ];

    const holidays = ["New Year's Day", "Memorial Day", "Independence Day", "Labor Day", "Thanksgiving", "Christmas", "Martin Luther King Jr. Day", "Washington's Birthday", "Juneteenth", "Columbus Day", "Veterans Day"];

    const regularDays = ["Monday", "Monday Night", "Tuesday", "Tuesday Night", "Wednesday", "Wednesday Night", "Thursday", "Thursday Night", "Friday", "Friday Night", "Saturday", "Saturday Night", "Sunday", "Sunday Night", "Monday", "Monday Night"];
 
    const weatherObj1 = forecastData.map((forecast, index, array) => {
        let updatedName = forecast.name;
        // Condition: Replace previous holiday with previous regular day from next forecast
        for (let i = 0; i < holidays.length; i++) {
            if (forecast.name.includes(holidays[i]) && index < array.length - 1) {
                const nextName = array[index + 1].name;
                const nextRegularDayIndex = regularDays.indexOf(nextName);
                if (nextRegularDayIndex >= 1) {
                    const prevRegularDay = regularDays[nextRegularDayIndex - 1];
                    updatedName = prevRegularDay + ' ' + forecast.name.substring(forecast.name.indexOf(holidays[i]) + holidays[i].length);
                } else {
                    updatedName = 'Today'; // If no previous regular day, fallback to 'Today'
                }
                break;
            }
        }    

        return {
            name: updatedName,
            temperature: forecast.temperature,
            shortForecast: forecast.shortForecast,
            windSpeed: forecast.windSpeed,
            probabilityOfPrecipitation: forecast.probabilityOfPrecipitation.value,
        };
    });
    
    console.log("weatherObj1",weatherObj1);
    const weatherIcon = getWeatherIcon(weatherObj1[0].shortForecast);
    const displayForecast = weatherObj1[0].shortForecast;
    const splitForecast = displayForecast.split(/(?:\s+then\s+|\s+And\s+)/)[0];

    return (
        <CardSummary>
            <div className="popup" style={{ position: 'relative' }}>
                <div className="card-body">
                    <h5 className="card-title"><span onClick={handleClick}>Weather Forecast <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} /></span></h5>
                    <div className="text-white h6 card-text"><span onClick={handleClick}>{weatherObj1[0].temperature} °F</span></div>
                    <div className="text-white h6 card-text"><span onClick={handleClick}>{splitForecast}</span></div>
                    <div className="text-white h6 card-text"><span onClick={handleClick}>Wind Speed: {weatherObj1[0].windSpeed}</span></div>
                </div>
                <span className={showTable ? "popuptext show" : "popuptext"} id="myPopup" onClick={handleClick}>
                    <div className="col-sm-12 no-gutters">
                        <Card4Opacity80>
                        <div className="mb-3 popup-header" onClick={(e) => handleTableHeaderClick(e)}>
                                <b className="mb-3" >Detailed Forecast For Next 7 Days</b>
                            </div>
                            <div className="d-flex align-items-center mb-1">
                                <WeatherTableContainer
                                    columns={columns}
                                    data={weatherObj1}
                                    />
                            </div>
                        </Card4Opacity80>
                    </div>
                </span>
            </div>
            <div className="stats-desc text-right py-2 px-2 " style={{  "position": "absolute", "top": "0", "right": "0" }}>{weatherIcon}</div>
            <div className="stats-desc text-right py-2 px-2 " style={{ "position": "absolute", "bottom": "0", "right": "0", "cursor": "pointer" }}>
                <span onClick={handleClick}>Next 7 Days <span className="fas fa-angle-double-down "></span></span>
            </div>
        </CardSummary>
    );    
};