import React, { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { SingleSelectDropDownControl } from "../controls/multiselect_control";
import { Form, Button, Modal, ListGroup, Badge } from "react-bootstrap";
import { getActiveSites, readFileData, getSiteDIDs } from "../../utils/apiCall";
import { GetFQURLs } from "../model/store.swr";
import { useCriteriaContext } from "../../context/criteria.context";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import { InfoStyle } from "../sitemaintenance";
import { getUserPortfolios } from "../auth/userinfo";
import { CSVLink } from "react-csv";

const Heading = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .add-button {
    background: #014059;
    font-size: 16px;

    :active,
    :hover,
    :focus {
      background: #014059 !important;
    }
  }

  .section {
    flex-direction: row;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .timezone {
    font-size: 12px;
    color: #8693ab;
  }
`;

const SiteDataTable = (props) => {
  const {data} = props
  const styles = {
    wrapText: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      maxWidth: '200px', /* Set a maximum width for the column */
    } 
  }

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const columns = [{
    dataField: 'site_id',
    text: 'Site ID',
    headerStyle: {width: '15%'},
    style: styles.wrapText
  },{
    dataField: 'zone_id',
    text: 'Zone ID',
    headerStyle: {width: '15%'}
  },
  {
    dataField: 'tracker_id',
    text: 'Tracker ID',
    headerStyle: {width: '15%'}
  },
  {
    dataField: 'device_id',
    text: 'Device ID',
    headerStyle: {width: '15%'},
    style: styles.wrapText
  }]

  console.log("site data is....",data)

  return  <div className="report-table">
    <BootstrapTable keyField='site_id' columns={ columns } data={data} pagination={ paginationFactory(options)}/>
  </div>
}

export const ImportData = () => {
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({});
  const [siteData, setSiteData] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false)
  const criteria = useCriteriaContext();
  const hiddenFileInput = useRef(null)

  useEffect(() => {
    const pf = getUserPortfolios()
    const fetchSites = async () => {
      const urls = [{ name: "acitve-sites", url: "meta_active_sites" }];
      const u = GetFQURLs(urls, criteria, "json", null, null, null, {}, {portfolioId: pf ? pf : ''});
      await getActiveSites(u[0].url, setSites, setError);
    };

    fetchSites();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("")
      setSuccessMsg("")
    },500)
  },[error,successMsg])

  console.log("site is++++", site);

  const handleSubmit = async () => {
    if(site && !site.value){
      setError("Site is required")
      return
    }else if(!file){
      setError("File is required")
      return
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("site_id", site.value);
    await readFileData(formData,setSuccessMsg,setError);
    setSite({})
    setFile(null)
    hiddenFileInput.current.value = ''
  };

  const handleFileUpload = () => {
    hiddenFileInput.current.click()
  }

  const getSiteData = async (siteId) => {
    setLoading(true)
    const urls = [{url: 'meta_site_data'}]
    const u = GetFQURLs(
      urls,
      criteria,
      "json",
      null,
      null,
      null,
      {},
      { siteId: siteId}
    );
    await getSiteDIDs(u[0].url, setSiteData, setError)
    setLoading(false)
  }


  return (
    <Fragment>
      {successMsg ? <InfoStyle type={'success'}> {successMsg} </InfoStyle> : 
      error ? <InfoStyle type={''}> {error} </InfoStyle> : null
      }
      <Heading>
        <h3>Import DID's</h3>
      </Heading>
      <div className="row" style={{position: 'relative', left: '5px'}}>
        <div className="col-3 site-dropdown">
          <Form.Label>Select Site</Form.Label>
          <SingleSelectDropDownControl
            value={site}
            handleChange={(val) => {setSite(val); setSiteData([]);getSiteData(val.value)}}
            options={sites}
            multi={false}
          />
        </div>
        <div className="col-2 file-input">
          {/* <Form.Label>Upload file</Form.Label> */}
          <Button
            onClick={handleFileUpload}
            className="btn-secondary import-btn"
            active
            color="secondary"
          >
            Upload DID Sheet
          </Button>
          <p style={{marginTop: '30px'}}>{file && file.name}</p>
          <input
            type="file"
            style={{display: 'none'}}
            ref={hiddenFileInput}
            // className="file"
            onChange={(event) => setFile(event.target.files[0])}
          />
        </div>
        <div className="col-2">
          <Button
            onClick={handleSubmit}
            className="btn-secondary import-btn"
            active
            color="secondary"
          >
            Read File
          </Button>
        </div>
      </div>
     {
      site && site.value ? (
        <div className="row" style={{position: 'relative', top: '30px'}}>
          <Heading>
            <h5>DID's for {site.value}</h5>
            {
              siteData.length ? (
                <CSVLink filename={`${site.value}_data`} data={siteData}  style={{position: 'relative', top: '-5px', left: '10%'}}>
                  <img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" />
                </CSVLink>
              ) : null
            }
            
          </Heading>
          {siteData.length ? <SiteDataTable data={siteData}/> : <h5 style={{position: 'absolute', top: '35vh', left: '35vw'}}>{loading ? 'Fetching data...' : 'No Data Available'}</h5>}
        </div>
      ) : null
     }
      
      <style>
        {`
            .file-input {
              position: relative;
              overflow: hidden;
              display: inline-block;
              text-algin: center;
            }

            .file-input .file {
              position: absolute;
              top: 25px;
              right: 0;
              margin: 0;
              padding: 0;
              font-size: 100px;
              cursor: pointer;
              opacity: 0;
              height: 100%;
            }
            
            .import-btn{
              position: relative;
              top: 25px;
            }
            
            .site-dropdown .css-t6do6-control{
              height: 40px !important;
            }
          `}
      </style>
    </Fragment>
  );
};
