import axios from 'axios'
import {logout} from '../components/auth/Login'
import { postJSONData } from '../components/model/store.swr'
import { getAccessToken } from '../components/auth/userinfo'



export const createActivityLog = (data) => {
    postJSONData(
        "/activity/log?restype=json",
        data,
        (res) => {
            console.log("checking response",res)
        }
    )
}

export const mailErrorLogs = (data) => {
    postJSONData(
        "/error/log?restype=json",
        data,
        (res) => {
            console.log("checking response",res)
        }
    )
}

export const getStatsData = (url, setResponse, setError) => {
    axios.get(url, {
        headers: { 'x-access-token': getAccessToken(), 
        "Content-Type": "application/json" }
    }).then(response => {
        setResponse(response.data)
    }).catch(error => {
        setError(error)
    })
}


export const getRecipientEmailIds = (url, setResponse, setError) => {
    axios.get(url, {
        headers: { 'x-access-token': getAccessToken(), 
        "Content-Type": "application/json" }
    }).then(response => {
        setResponse(response.data)
    }).catch(error => {
        setError(error)
    })
}


export const scheduleReport = async (url, setResponse, setError, setMsgStyle, resetFormData, forDownloads, data) => {
    try{
        let response 
        if(forDownloads){
            response = await axios.post(url, data.body, {
                headers: data.headers 
            })
        }else{
            response = await axios.get(url, {
                headers: { 'x-access-token': getAccessToken(), 
                "Content-Type": "application/json" }
            })
        }
        
        if(response && response.status == 200){
            setResponse(response.data)
            setMsgStyle("success")
            resetFormData()
        }
    }catch(error){
        setError(error)
        setMsgStyle("warning")
    }
    
}

export const updateReport = async (url, setResponse, setError, setMsgStyle, resetFormData) => {
    try{
        const response = await axios.get(url, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })
        if(response && response.status == 200){
            setResponse(response.data)
            setMsgStyle("success")
            resetFormData()
        }
    }catch(error){
        setError(error)
        setMsgStyle("warning")
    }
    
}

export const updateReportStatus = async (payload, setResponse, setError, setMsgStyle, resetFormData) => {
    try{
        const response = await axios.post('/update-report-status', payload,{
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })
        if(response && response.status == 200){
            setResponse(response.data)
            setMsgStyle("success")
            resetFormData()
        }
    }catch(error){
        setError(error)
        setMsgStyle("warning")
    }
    
}




export const generateReport = async (url, setResponse, setError) => {
    try{
        const response = await axios.get(url, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })
    
        if(response && response.status == 200){
            setResponse(response.data)
        }
    }catch(error){
        setError(error)
    }
    
}

export const getReports = async (url, setResponse, setError) => {
    try{
        const response = await axios.get(url, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })
    
        if(response && response.status == 200){
            setResponse(response.data)
        }
    }catch(error){
        setError(error)
    }
}

export const deleteReport = async (payload, setResponse, setError) => {
    try{
        const response = await axios.get(`/delete-report?user_id=${payload.user_id}&title=${payload.title}`, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })

        if(response && response.status == 200){
            setResponse(response.data)
        }
    }catch(error){
        setError(error)
    }

}

export const getActiveSites = async (url, setResponse, setError) => {
    try{
        const response = await axios.get(url, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "application/json" }
        })

        if(response && response.status == 200 && response.data && response.data.length){
            console.log("checking response.....",response)
            setResponse(response.data.map(d => {
                return {label: d.site_id, value: d.site_id}
            }))
        }
    }catch(error){
        setError(error)
    }

}

export const readFileData = async (formData, setResponse, setError) => {
    try{
        const response = await axios.post('/read-file', formData, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "multipart/form-data" }
        })

        if(response && response.status == 200){
            console.log("checking response.....",response)
            setResponse(response.data.message)
            // setResponse(response.data.map(d => {
            //     return {label: d.site_id, value: d.site_id}
            // }))
        }
    }catch(error){
        setError(error)
    }

}


export const getSiteDIDs = async (url, setResponse, setError) => {
    try{
        const response = await axios.get(url, {
            headers: { 'x-access-token': getAccessToken(), 
            "Content-Type": "multipart/form-data" }
        })

        if(response && response.status == 200){
            console.log("checking response.....",response)
            setResponse(response.data)
        }
    }catch(error){
        setError(error)
    }

}



export const getCrumbsData = async (payload, setResponse, setError) => {
    

    postJSONData(
        `/meta?querytype=meta_domain_by_id&restype=json`,
        payload,
        (res) => {
            if(res && res.length){
                const data = res.map(ele => {
                    return {label: ele.id, value: ele.id}
                })
                setResponse(data)
            }
        }
    )
    
}
