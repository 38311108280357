// import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useMemo, useState } from "react"
import { Form, Nav, Navbar, NavDropdown,Modal, Card, Button  } from "react-bootstrap"
import { useDialogActionContext } from "../../context/dialog.context"
import { useStore } from "../../context/store.context"
import { MetaSitesBaseView } from "../../forms/allsites"
import { UDCEditor } from "../../forms/editcharts"
import { ChartEditor } from "../../forms/editchart"
import ReportEditor  from "../../forms/editreports"
import { UserPortfolioBaseView } from "../../forms/portfolio"
import { UserInvitation } from "../../forms/userinvite"
import { logout } from "../auth/Login"
import ChangePassword from "../auth/password"

import { getUserName, isController, isAdminUser,getUser,getAccessToken,isNotificationServiceUser,isHailAdminUser,isHailUser, ischangePasswordPage ,isSunpathUser } from "../auth/userinfo"
import Criteria, { isDevice, isPortfolio, isSite, isZone } from './criteria'
import { detailsPage, sessionPage, settingsPage, energyMeterPage, usePageActionContext, usePageContext, addonfeaturePage,changePasswordPage,  diffusePage} from "../../context/page.context"

import {IoSettingsOutline, IoSunnyOutline } from 'react-icons/io5'
import { TbSolarPanel, TbSunset2 } from "react-icons/tb"
import { CgExtensionAdd } from "react-icons/cg";
import { RiHailLine } from "react-icons/ri";
import { FaLockOpen, FaLock  } from "react-icons/fa";
import { TbAlertTriangle } from "react-icons/tb";
import Crumbs from './crumbs';
import DomainSearchBox  from "./search"
import './layout.css'
import criteria from "./criteria"
import { useCriteriaContext } from "../../context/criteria.context";
import { toJS } from 'mobx';
import ReleaseSummary from "../notifications"
// import { useReportContext } from "../../context/report.context"

import ReactTooltip from "react-tooltip";
import appConfig from '../../config.json';
// import appConfig from '/etc/data/config.json';

import styled from 'styled-components';
const newChartProps = { loading: true, selectOnly: false }
const editSitesProps = { loading: true, selectOnly: false }
const inviteUserProps = { loading: true, selectOnly: false }
const selectSitesProps = { loading: true, selectOnly: true }

export const MenuBar = () => {
    const storeContext = useStore()
    const dialogActionContext = useDialogActionContext()
    const navFilter = useMemo(() => {return storeContext.navFilter}, [storeContext.navFilter])
    const pageContext = usePageContext()
    const pageAction = usePageActionContext()
    const c = useCriteriaContext()
    const cObj = isPortfolio(c) ? {portfolio: 'pf'} : isSite(c) ? {site: 'site'} : isZone(c) ? {zone: 'zone'} : isDevice(c) ? {device: 'rc'} : {}
    const cData = toJS(useStore().criteria)
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    
    const [showCard, setShowCard] = useState(false);
    const handleIconClick = () => {
        setShowModal1(true);
    };
    const handleCloseModal = () => {
        setShowModal1(false); 
    };
    const handleCloseModal2 = () => {
        setShowModal2(false);   
    };
    const handleShowModal = () => {
        setShowModal2(true);
    };
    const navigateUrl = (url) => {
        const urlWithParam = url+'?e='+getUser().email+'&t='+ getAccessToken();
        const newWindow = window.open(urlWithParam, '_self', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    const handleUserAnaltyics = () => {
        pageAction.setPage("User Analytics", [], false)
    }

    const handleDataImport = () => {
        pageAction.setPage("Data Import", [], false)
    }

    const handleHailSettings = () => {
        pageAction.setPage("Settings", [], false)
        handleCloseModal()
    }
    const handleDiffuseSettings = () => {
        pageAction.setPage("Diffuse", [], false)
        handleCloseModal()
    }

    const cardStyle = {
        width: '100%',
        height: '100%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
    }
    const cardHeader ={
        border: 'none', padding: '0', marginTop: '40px' , backgroundColor: 'transparent', fontWeight: 'bold', fontSize: '15px'
    }
    const cardTestStyle={
        marginTop: '40px', fontSize: '14px', width: '200px', height: '100px'
    }
    const AddonFeatureIcon = styled(CgExtensionAdd)`
    font-size: 35px;
    color: white;
    `;
    const ModalHeader = styled(Modal.Header)`
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    `;

    const CustomModalTitle = styled(Modal.Title)`
    margin: auto;
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 1px;
`;
    const CustomButton = styled(Button)`
    background-color: #004080;
    border-color: #ffffff;
    color: #ffffff;
    `;
    const CustomModalHeader = styled(Modal.Header)`
    display: flex;
    font-weight: bold;
    font-size: 15px;
    background-color: #f5f5f5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    `;

    const CustomModalBody = styled(Modal.Body)`
    border: none;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    `;

    const CustomModalFooter = styled(Modal.Footer)`
    height: 60px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    `;


    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" bg="dark" sticky="top" className="custom-navbar zoom_90_per">
            <div className="container-fluid">
               
                <Navbar.Brand  href={navFilter? navFilter.location.url: "/"}>
                    <img
                        src="../assets/img/logo/logo3.svg"
                        alt="FTC SUNOPS"
                        width="104"
                        height="38"
                        className="App-logo"
                    />
                    {/* <b>Voyager</b> Dashboard */}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar style={{padding: '0px', marginLeft: '4%'}} className='translate-x'>
                {pageContext.page !== sessionPage && <Crumbs />}

                {pageContext.page !== sessionPage && pageContext.page !== settingsPage && pageContext.page !== addonfeaturePage && pageContext.page !== changePasswordPage && pageContext.page !== diffusePage &&<DomainSearchBox />}

                </Navbar>

                <Navbar className="calendar-container">
                    <Form className="d-flex">
                    {pageContext.page !== sessionPage && pageContext.page !== sessionPage && pageContext.page !== diffusePage && <Criteria hidden={pageContext.page === detailsPage} />}
                    </Form>
                </Navbar>
                <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav ">
                <div>
                    <Nav className="justify-content-end" style={{paddingRight: '10px'}}>
                    {!isPortfolio(c) ? (
                    <AddonFeatureIcon onClick={handleIconClick} data-tip data-for='addOnFeature' />) : null}
                    <Modal show={showModal1} onHide={handleCloseModal} className={showModal2 ? 'blur-background' : ''}style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', paddingTop:"50px", filter: showModal2 ? 'blur(2px)' : 'none' }} >
                        <ModalHeader closeButton>
                            <CustomModalTitle>Add-on Features</CustomModalTitle>
                        </ModalHeader>`
                        <Modal.Body style={{ paddingTop: '1px' }}>
                            {/*  modal content goes here */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginLeft: '1%' }}>
                                    <Card style={cardStyle}>
                                        <Card.Body>
                                            <div style={{ position: 'relative' }}>
                                                <RiHailLine style={{ position: 'absolute', top: 0, left: 0, fontSize: '24px', color: '#0080ff'}} />
                                            </div>
                                            <Card.Header style={cardHeader}>
                                                Automatic Hail Stow
                                            </Card.Header>
                                            <Card.Text style={cardTestStyle}>
                                               Forecast service providing notifications and automated tracker stow prior to hail events.
                                            </Card.Text>
                                            { isHailUser() ? (
                                            <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                            <CustomButton variant="outline-info" onClick={handleHailSettings}>Hail</CustomButton>
                                            <FaLockOpen style={{ color:'#004080', fontSize: '25px',  marginLeft: '10'}} />
                                            </div>
                                            ) : 
                                            <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                            <CustomButton variant="outline-info" onClick={handleShowModal}>Hail</CustomButton>
                                                <Modal show={showModal2} onHide={handleCloseModal2} style={{ paddingTop:"100px", width: '30%', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                                    <CustomModalHeader >Feature Subscription Required </CustomModalHeader>
                                                    <Modal.Body style={{border: 'none', fontWeight: 'bold',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',backgroundColor:'#f5f5f5'}} >
                                                    <p style={{ marginBottom: '20px' }}>We're sorry, but it seems you're trying to access a feature that requires a subscription. This feature is not available on your current plan.</p>
                                                    <p>To unlock access to this feature, please consider upgrading your subscription plan. You can do this by contacting our support team for assistance.</p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ height: '60px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor:'#f5f5f5' }}> 
                                                    <button type="button" className="btn btn-primary" style={{ position: 'absolute', bottom: 'px', left: '370px',backgroundColor: '#004080',borderColor: '#ffffff',color: '#ffffff' }} onClick={handleCloseModal2}>OK</button>   
                                                    </Modal.Footer>
                                                </Modal>                             
                                            <FaLock style={{ color:'#004080', fontSize: '25px',  marginLeft: '10'}} />
                                            </div>}
                                        </Card.Body>
                                    </Card>     
                            </div>

                            <div style={{ marginLeft: '1%' }}>
                                <Card Card style={cardStyle}>
                                    <Card.Body>
                                        <div style={{ position: 'relative' }}>
                                            <TbSunset2 style={{ position: 'absolute', top: '-14px', left: 0, fontSize: '24px', color:"orange" }} />
                                            <TbSolarPanel style={{ position: 'absolute', top: 0, left: 0, fontSize: '24px' }} />
                                        </div>
                                        <Card.Header style={cardHeader}>
                                            SUNPATH
                                        </Card.Header>
                                        <Card.Text style={cardTestStyle}>
                                            Tracker performance optimization for undulating terrain and diffuse conditions.
                                        </Card.Text>
                                        { isSunpathUser() ? (
                                            <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                            <CustomButton variant="outline-info" onClick={handleDiffuseSettings}>Diffuse</CustomButton>
                                            <FaLockOpen style={{ color:'#004080', fontSize: '25px',  marginLeft: '10'}} />
                                            </div>
                                            ) : 
                                            <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                            <CustomButton variant="outline-info" onClick={handleShowModal}>Diffuse</CustomButton>
                                                <Modal show={showModal2} onHide={handleCloseModal2} style={{ paddingTop:"100px", width: '30%', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                                    <CustomModalHeader >Feature Subscription Required </CustomModalHeader>
                                                    <Modal.Body style={{border: 'none', fontWeight: 'bold',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',backgroundColor:'#f5f5f5'}} >
                                                    <p style={{ marginBottom: '20px' }}>We're sorry, but it seems you're trying to access a feature that requires a subscription. This feature is not available on your current plan.</p>
                                                    <p>To unlock access to this feature, please consider upgrading your subscription plan. You can do this by contacting our support team for assistance.</p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ height: '60px',boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor:'#f5f5f5' }}> 
                                                    <button type="button" className="btn btn-primary" style={{ position: 'absolute', bottom: 'px', left: '370px',backgroundColor: '#004080',borderColor: '#ffffff',color: '#ffffff' }} onClick={handleCloseModal2}>OK</button>   
                                                    </Modal.Footer>
                                                </Modal>                             
                                            <FaLock style={{ color:'#004080', fontSize: '25px',  marginLeft: '10'}} />
                                            </div>}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                         </Modal.Body>
                    </Modal>
                    <ReactTooltip id='addOnFeature' place="left" type="light" effect="solid" >
                            <span>Add-On Features</span>
                    </ReactTooltip> 
                    </Nav>
                </div>
 
                    {
                                (isNotificationServiceUser() && !ischangePasswordPage()) ? (
                                    <Nav className="justify-content-end" style={{paddingRight: '10px',cursor: "pointer"}}>
                                    <img
                                        src="../assets/img/logo/email-notification-alert-icon.svg"
                                        alt="Notification Service"
                                        width="30px"
                                        height="30px"
                                        onClick={()=>navigateUrl(appConfig ? appConfig.notificationServiceUrl : "https://dev-ftc-notification-service.centralus.cloudapp.azure.com/#/")}
                                        data-tip
                                        data-for='notificationIcon'
                                    />
                                    <ReactTooltip id='notificationIcon' place="right" type="light" effect="solid">
                                        <span>Notification Service</span>
                                    </ReactTooltip>  
                                    </Nav>
                                    
                                ) : null
                            }
                
                    <Nav className="justify-content-end " style={{paddingRight: '5px'}}>
                        {(!ischangePasswordPage() && !isHailAdminUser()) ? (
                        <NavDropdown className="nav-item-setting" title={<IoSettingsOutline size="30px"/>} id="collasible-nav-dropdown">
                            {/* <NavDropdown.Item title="Manage Charts" onClick={() => {dialogActionContext.openDialog({ showDialog: true, title: 'Manage Charts', customClass:'manageCharts', Content: ChartEditor, args: newChartProps })}}>Edit Chart</NavDropdown.Item>
                            <NavDropdown.Divider/> */}
                            
                            {
                                isAdminUser() ? (
                                    <>
                                        <NavDropdown.Item title="Edit Reports" onClick={() => {dialogActionContext.openDialog({ showDialog: true, title: 'Manage Reports', customClass:'manageCharts', Content: ReportEditor, args: newChartProps })}}>Edit Report</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="Edit Sites" onClick={() => {dialogActionContext.openDialog({ showDialog: true, title: 'Edit Sites', Content: MetaSitesBaseView, args: editSitesProps })}}>Edit Sites</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="Edit Portfolios" onClick={() => {dialogActionContext.openDialog({ showDialog: true, title: 'Edit Portfolio', Content: UserPortfolioBaseView, args: selectSitesProps })}}>Edit Portfolio</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="Invite User" onClick={() => dialogActionContext.openDialog({ showDialog: true, title: 'Sign Up - New User', Content: UserInvitation, args: inviteUserProps })}>Invite User</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="User Analytics" onClick={handleUserAnaltyics}>User Analytics</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="Import Data" onClick={handleDataImport}>Import Data</NavDropdown.Item>
                                    </>
                                ) : null
                            }
                            {
                                 !isAdminUser() ? (
                                    <>
                                        <NavDropdown.Item title="Hail settings" onClick={handleHailSettings}>Hail Settings</NavDropdown.Item> 
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item title="Import Data" onClick={handleDataImport}>Import Data</NavDropdown.Item> 
                                    </>
                                ) : null
}
                        </NavDropdown>
                         ) : null}           
                    </Nav>  
                    {(!isHailAdminUser() && !ischangePasswordPage()) ? (
                    <Nav className="justify-content-end" style={{paddingRight: '10px'}}>
                        <ReleaseSummary />
                    </Nav>
                    ) : null}        
                    <Nav className="justify-content-end">
                            <NavDropdown className="nav-item-custom" title={<span style={{background: 'white', color: '#4C4345', borderRadius: '50%', padding: '10px', fontWeight: '600'}}>{getUserName()}</span>} id="collasible-nav-dropdown">

                            {(!ischangePasswordPage()) ? (
                                <NavDropdown.Item title="Change Password" disabled={isController()} onClick={() => dialogActionContext.openDialog({ showDialog: true, size: 4, title: 'Change Password', Content: ChangePassword })}>Change Password</NavDropdown.Item>
                            ) : null}
                            <NavDropdown.Divider />
                            <NavDropdown.Item title="Customer Support"  onClick={() => window.open("https://support.sunops.ftcsolar.com/support/home", '_blank').focus()}>Customer Support</NavDropdown.Item>  
                            <NavDropdown.Divider />
                            <NavDropdown.Item title="Logout"  onClick={() => logout()}>Logout</NavDropdown.Item>
                            </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                    
            </div>
        </Navbar>
    )
}