
import React from 'react';
import { WiDaySunny, WiCloudy, WiRain, WiSnow, WiFog, WiHail, WiNightClear, WiThunderstorm,   } from "react-icons/wi";
import { BsCloudLightningRain } from "react-icons/bs";
import { Table } from 'react-bootstrap';
import './WeatherTableContainer.css'; 

 export  const getWeatherIcon = (shortForecast) => {
        const lowerCaseForecast = shortForecast.toLowerCase();
        if (lowerCaseForecast.includes('sunny')) {
            return <WiDaySunny size="48px" color="#004059" />;
        } else if (lowerCaseForecast.includes('cloudy') || lowerCaseForecast.includes('overcast')) {
            return <WiCloudy size="48px" color="#004059" />;
        } else if (lowerCaseForecast.includes('rain')) {
            return <WiRain size="48px" color="#004059"  />;
        } else if (lowerCaseForecast.includes('snow')) {
            return <WiSnow size="48px" color="#004059"  />;
        } else if (lowerCaseForecast.includes('fog') || lowerCaseForecast.includes('mist')) {
            return <WiFog  size="48px" color="#004059" />;
        } else if (lowerCaseForecast.includes('hail')) {
            return <WiHail  size="48px" color="#004059" />;
        } else if (lowerCaseForecast.includes('clear')) {
            return <WiNightClear  size="48px" color="#004059" />;
        }else if (lowerCaseForecast.includes('thunderstorm') || lowerCaseForecast.includes('storms')) {
            return <WiThunderstorm  size="48px" color="#004059" />;
        }else if (lowerCaseForecast.includes('lightning')) {
            return <BsCloudLightningRain  size="48px" color="#004059" />;
        }  else {
            return null;
        }
    };
    
    export const WeatherTableContainer = ({ columns, data, onRowClick }) => {
      const handleRowClick = (e, row) => {
        e.stopPropagation(); 
        if (onRowClick) {
          onRowClick(e, row);
        }
      };
    
      return (
        <Table responsive hover className="custom-table">
          <thead>
            <tr onClick={(e) => handleRowClick(e, null)}>
              {columns.map((column, colIndex) => (
                <th key={colIndex} onClick={(e) => handleRowClick(e, null)}>{column.Header}</th> 
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={(e) => handleRowClick(e, row)}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className="custom-cell" style={{ 
                    maxWidth: column.accessor === 'shortForecast' ? '250px' : 'none',
                    whiteSpace: column.accessor === 'shortForecast' ? 'pre-wrap' : 'normal',
                    textAlign: column.accessor === 'temperature' || column.accessor === 'probabilityOfPrecipitation' ? 'center' : 'left'
                  }}>                       
                    {row[column.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };