import React from "react";
import { useState, useEffect } from "react";
import { useDataviewActionContext } from "../../context/dataview.context";
import { issueSummaryColumns, issueDetailColumns } from "./columns";
import { getLevel, getLogData } from "../../helpers/common";
import { useStore } from "../../context/store.context";
import { CardHeader } from "../layout/Card";
import Table from "./Table";
import {
    DownloadCsv_Issue_Summary_table,
    getData,
    showArrow,
    sortColumn,
    timeRangeCheck,
} from "./issueIdentificationUtils";
import { InfoTooltip } from "../../model/info-tooltip";
import { TooltipContent } from "./TooltipContent";
import ReactDOMServer from 'react-dom/server';

const PfIssueSummary = () => {
    const criteriaContext = useStore().criteria;
    const [pfDetailColumns, setPFDetailColumns] = useState([
        ...issueDetailColumns,
    ]);
    const [pfSummaryColumns, setPfSummaryColumns] = useState([
        ...issueSummaryColumns,
    ]);
    const [tenant, setTenant] = useState("");
    const [level, setLevel] = useState(getLevel(criteriaContext));
    const [sortedRow, setSortedRow] = useState(
        pfSummaryColumns.map((t) => ({
            ...t,
            sorted: t.dataField === "ava_impact" ? "desc" : null,
        }))
    );
    const [summaryData, setSummaryData] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [detailData, setDetailData] = useState([]);
    const [timezone, setTimeZone] = useState("Local");
    const urls = [
        { name: "issueSummary", url: "issue_summary", grouptype: "pf" },
    ];
    const issueFileName = `${criteriaContext.pf}-${criteriaContext.Ts}-${criteriaContext.Te}`;
    const tooltip = (
        <div>
            <h5>{TooltipContent.IssueSummary.header}</h5>
            {TooltipContent.IssueSummary.body}
        </div>
    )
    useEffect(() => {
        const fetchData = () => {
            setPfSummaryColumns(
                pfSummaryColumns.map((t) => ({
                    ...t,
                    text:
                        t.text === "Trackers Impacted"
                            ? "Sites Impacted"
                            : t.text,
                }))
            );
            setPFDetailColumns(pfDetailColumns)
            const newSort = pfSummaryColumns.map((t) => ({
                ...t,
                sorted: t.dataField === "ava_impact" ? "desc" : null,
            }));
            setSortedRow(newSort);
            const log = getLogData(criteriaContext, {
                featureId: `view-issue-summary-pf-summary`,
                usecase: `view issue summary pf table`,
                category: "overview",
                level: level,
                details: JSON.stringify({ tenant: tenant }),
            });
            getData(
                urls,
                criteriaContext,
                log,
                setSummaryData,
                newSort,
                setDetailData,
                setTimeZone,
                level,
                setIsReady,
            );
        };
        fetchData();
    }, [criteriaContext.pf, criteriaContext.Ts, criteriaContext.Te]);

    const headerFormatter = (column) => {
        const value = column.dataField;
        return (
            <React.Fragment>
                <span
                    onClick={() =>
                        sortColumn(
                            value,
                            summaryData,
                            setSummaryData,
                            sortedRow,
                            setSortedRow
                        )
                    }
                    style={{ cursor: "pointer" }}
                >
                    {column.text} {showArrow(sortedRow, value)}
                </span>
            </React.Fragment>
        );
    };

    const RenderHeader = () => {
        return (
            <CardHeader title={"Issue Summary"}>
                <div
                    style={{
                        marginRight: 20,
                        fontSize: 12,
                        color: "#8693AB",
                    }}
                >
                    Timezone: {timezone}
                </div>
                <DownloadCsv_Issue_Summary_table
                    data={summaryData}
                    filename={`${criteriaContext.pf}-${criteriaContext.Ts}-${criteriaContext.Te}-Issue-Summary`}
                    level={level}
                />
                <div style={{ paddingTop: "0.1rem" }}>
                    <InfoTooltip
                        content={ReactDOMServer.renderToString(tooltip)}
                        toolTipPointer={"left"}
                    />
                </div>
            </CardHeader>
        );
    };

    const rowAttr = pfSummaryColumns
        .filter((e) => e.selected)
        .map((t) => ({ ...t, headerFormatter }));

    if (!timeRangeCheck(criteriaContext.Ts, criteriaContext.Te)) {
        return (
            <>
                <div style={{ marginLeft: "-3px", marginRight: "-3px" }}>
                    <div className="row">
                        <div className="col-12 card border-0 text-truncate mb-3 chart-card">
                            <div className="card-body">
                                <div>
                                    <RenderHeader />
                                    <div className="text-center">
                                        <div
                                            className="text-center"
                                            style={{
                                                fontSize: "medium",
                                                fontStyle: "oblique",
                                            }}
                                        >
                                            {
                                                "The issue summary table displays data populated for dates greater than January 1st, 2023."
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (!isReady) {
        return (
            <>
                <div style={{ marginLeft: "-3px", marginRight: "-3px" }}>
                    <div className="row">
                        <div className="col-12 card border-0 text-truncate mb-3 chart-card">
                            <div className="card-body">
                                <div>
                                    <RenderHeader />{" "}
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div style={{ marginLeft: "-3px", marginRight: "-3px" }}>
                <div className="row">
                    <div className="col-12 card border-0 text-truncate mb-3 chart-card">
                        <div className="card-body">
                            <div>
                                <RenderHeader />{" "}
                                {summaryData.length > 0 ? (
                                    <Table
                                        data={summaryData}
                                        rowAttrbs={rowAttr}
                                        childData={detailData}
                                        detailTableColumns={pfDetailColumns}
                                        level={level}
                                        fileName={issueFileName}
                                    />
                                ) : (
                                    <div
                                        className="text-center"
                                        style={{
                                            fontSize: "medium",
                                            fontStyle: "oblique",
                                        }}
                                    >
                                        {
                                            "The Portfolio is currently not experiencing any issues within the selected time range."
                                        }
                                    </div>
                                )}
                            </div>
                            <div style={{textAlign: "right"}}>
                                {"** Disclaimer: Portfolios that have not reported in the last 90 days will be excluded from the table."}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PfIssueSummary;