import { observer } from 'mobx-react';
import React from 'react';
import { CardPopover, CardStats } from '../layout/Card';
import NotifyTooltip from '../../model/notify-tooltip'

import { InfoTooltip } from '../../model/info-tooltip'
import ReactDOMServer from 'react-dom/server';

const ProgressBar = ({ value }) => {
  return (
    <div className="stats-progress progress bg-dark-darker my-2">
      { (value) ? <div className="progress-bar " style={{ "width": value + "%" }}></div> : <div ></div>}
    </div>
  )
}


const StatsContent = (props) => {
  const value = (props.data.value2) ? <span>{props.data.value}<span className="h5"> / {props.data.value2}</span></span> : <span>{props.data.value}</span>;
  const tooltip = props.tooltip ? (
    <div>
        <h5>{props.tooltip.header}</h5>
        {props.tooltip.body}
    </div>
  ) : ''
  
  return (
    <>
    <div className="card-body" onClick={props.onClick} style={{cursor: props.onClick ? 'pointer' : 'auto'}}>
    <h5 className="card-title">{props.title} <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} toolTipPointer={props.toolTipPointer}/></h5>
    {/* <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div> */}
    
    <h4 className="card-text">{props.data.value} <span className="h5"> {(props.data.value2) ? "/ "+ props.data.value2 : " "} {props.data.suffix}</span> {props.data.info ? 
      <NotifyTooltip  content =   {props.data.info} onClick={props.data.infoclick} />
      : null}</h4> 
    {(props.data.progress)? <ProgressBar value={props.data.progress}/> : ''}
  </div>
  {
    props.data.totalDataCount && props.data.totalDataCount.length>0 &&
    <h5 className="text-left my-2 mx-3" style={{"position":"absolute","bottom":"0", "left":"0"}}>{props.data.totalDataCount}</h5>
  }
  <h5 className="text-right my-2 mx-3" style={{"position":"absolute","bottom":"0", "right":"0"}}>{props.data.timeperiod}</h5>
    </>
  )

}
const StatsOverview = (props) => {

  return (
    <CardStats>
      <StatsContent {... props}/>
    </CardStats>


  );
}

export const StatsOverviewPopup = observer((props) => {

  return (
    <CardStats class="hover-effect">
      <CardPopover {... props}>
      <StatsContent {... props} />
      </CardPopover>
    </CardStats>


  );
})

export default observer(StatsOverview);
