import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import React,{useMemo, useEffect, useState} from "react";
import { TooltipHelpContent } from "../../components/pages/tooltip-help-content";
import withData from "../../model/combined";
import { axisStyle } from "./common";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getSites } from "../../model/metadata";
import { isPortfolio } from "../../components/layout/criteria";
import { useInView } from "react-intersection-observer";
import { useCriteriaContext } from "../../context/criteria.context";
import { useStore } from "../../context/store.context";
import { useAvailabilityContext } from "../../context/availability.context";


const nameStyle = { color: '#fff', fontSize: 14, fontWeight: 'bold' }

const defaultOption = {
        legend: { },
        grid: { left: "60", bottom: "15%", top: "10%", right: "5"},
        xAxis: [
            axisStyle("", -20, "start"),
        ],
        yAxis: [
            axisStyle("", 0, "end"),
        ]
    
}

function makeOptions(data, modeSummaryData) {
    if (!data || !data.values) return defaultOption
    data.values.forEach(v => {
        if(v[2] < 0){
            if(modeSummaryData.includes(moment.unix(v[0]).utc().format("YYYY-MM-DD"))){
                v[2] = 'Not Calculated'
            }else{
                v[2] = 'No Data'
            }
            
        }

        if(v[3] < 0){
            v[3] = 'No Data'
        } 
    })
    const sorted = data.values.sort((a, b) => a[1] - b[1])
    const xaxisdata = sorted.map(e => e[1])
    const option = {
        legend: {show: true},
        xAxis: [{
            type: 'category',
            boundaryGap: false,
            sortSeriesIndex: 1,
            data: sorted.map(e => e[1]), //.format("Do MMMM")),
            axisLabel: {
                formatter: (function (value) {
                    const l = moment.unix(value).utc().format("Do MMM@HH:mm")
                    return l
                })
            }
        }],
        // yAxis: [{min: 0}],
        tooltip: {
            formatter: (function (params) {
                // console.log(value)
                return ` <Strong> ${moment.unix(+params[0].name).utc().format("Do MMM@HH:mm")} </strong> <BR/> Accuracy: <b style='color:green'>${params[0].data} </b><BR/>  Availability: <b style='color:blue'>${params[1].data}</b> `
            }),
            trigger: 'axis',
            backgroundColor: '#B7D0DA',
            textStyle: { fontSize: 14, color: "#014059" },
        },
        series: [{
            data: sorted.map(e => e[2]),
            type: "line",
            symbolSize: 3,
            smooth: true,
            label: { show: false,  color: '#ccc', position: 'top', offset: [-5, -10] , 
                formatter: function (params) {
                        // console.log(params);
                        const v = params.data //params.value[params.encode.y[0]]
                        return v>0 ?  v : '';
                }      
            },
            lineStyle: {
                color: "rgba(1, 102, 141, 0.9)"
            },
            areaStyle: {
                color: "rgba(255, 236, 235, 0.6)",
            }
        }, {
            data: sorted.map(e => e[3]),
            type: "line",
            smooth: true,
            label: { show: false,  color: '#ccc', position: 'bottom', offset: [5, 10],
                formatter: function (params) {
                    // console.log(params);
                    const v = params.data //params.value[params.encode.y[0]]
                    return v>0 ?  v : '';
                }      
            },
            lineStyle: {
                color: "rgba(0, 0, 255, 0.9)"
            },
            areaStyle: {
                color: "rgba(64, 64, 128, 0.6)",
            }
        }]
    }
    return option;

}

function mergeOptions(data, args, criteria, userContext, modeSummaryData) {

    if (!data || Object.keys(data).length === 0) return defaultOption
    
    const uptimeOptions = makeOptions(data.rcuptime, modeSummaryData)
    return _.merge({}, defaultOption, uptimeOptions);

}

const UptimeSummaryBaseChart = (props) => {
    return (
        <ReactEcharts className="mw-100 mh-100"
            option={props.data}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            //style={{ height: "100%", width: "100%", left: 0, top: 0 }}
            onChartReady={props.onRenderComplete}
            opts={{ renderer: "svg" }}
            style={{ height: "25vh", left: -20, top: 0 }}

        />)
}
function todate(secs) {
    return new Date(secs * 1000)
}
export function transform_uptime(v, c) {
    //console.log(v, c);
    //if (c === 1) return v * 1000//moment.unix(v) //ts 
    return +v
}
//const keyf = function (o) { return ['site_id', 'zone_id', 'device_id'] } //{ return o.site_id + '-' + o.zone_id}
const urls = [
    { name: "rcuptime", url: "rc_uptime_daily_avg", transform: transform_uptime },
]

const getUrls = async (criteria) => {
    if(!isPortfolio(criteria)){
        return urls
    }
    const data = await new Promise((resolve,reject) => {
        getSites(criteria, resolve, reject)
    })

    if(Array.isArray(data)){
        if(data.length){
            var tenantList = []
            data.forEach(s => {
                if(s.portfolios.includes(criteria.pf)){
                    tenantList.push(s.tenant)
                }
            })
            tenantList = [...new Set(tenantList)]
            if(tenantList.length > 1){
                return [
                    { name: "rcuptime", url: "rc_uptime_daily_avg_combined", transform: transform_uptime },
                ]
            }else{
                return urls
            }
        }
    }
}

const comps = { 
    title: 'Historical Performance', 
    tooltip: TooltipHelpContent.UptimeSummary,
    chart: UptimeSummaryBaseChart,
    urls: getUrls,
    default: defaultOption,
    parser: { f: mergeOptions, args: {} },
    showTimezone: true
}

const UptimeSummary = React.memo(
    (props) => {
      const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
      });
      const [isInView, setIsInView] = useState(false);
      const c = useCriteriaContext();
      const availabilityContext = useAvailabilityContext()
      
      useEffect(() => {
        //console.log("PerformanceById render")
        setIsInView(inView);
      }, [inView]);
  
      
    const UptimeSummaryChart = useMemo(() => withData({ ...comps }),[isInView,c,availabilityContext.modeSummaryData])
         
      
      return <div ref={ref}>{isInView && <UptimeSummaryChart/>}</div>;
    },
    (prevProps, nextProps) => _.isEqual(1, 1)
  );

// const UptimeSummary = compose(withData)(UptimeSummaryBaseChart)
export default UptimeSummary;